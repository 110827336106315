var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "schedul-detail module-main-body" }, [
    _c(
      "div",
      { staticClass: "btn-container module-content-top-bar" },
      [
        ![4, 5, 6].includes(_vm.jobData.status) &&
        _vm.jobData.status &&
        !_vm.isCanceled &&
        _vm.hasPermission(_vm.$permissionFunctionCode.EDIT)
          ? _c(
              "el-button",
              {
                staticStyle: { "min-width": "100px" },
                attrs: {
                  type: "danger",
                  size: "small",
                  round: "",
                  loading: _vm.cancelLoading,
                },
                on: { click: _vm.cancelJop },
              },
              [_vm._v(" Cancel Job ")]
            )
          : _vm._e(),
        ![3, 4, 5, 6].includes(_vm.jobData.status)
          ? _c(
              "el-button",
              {
                staticStyle: { "min-width": "100px" },
                attrs: {
                  type: "primary",
                  size: "small",
                  round: "",
                  loading: _vm.submitLoading,
                },
                on: { click: _vm.submitForm },
              },
              [_vm._v(" Submit ")]
            )
          : _vm._e(),
        _vm.jobData.status == 4 && _vm.isJobCreatorOrSellerEditView
          ? _c(
              "el-button",
              {
                staticStyle: { "min-width": "100px" },
                attrs: {
                  type: "primary",
                  size: "small",
                  round: "",
                  loading: _vm.submitLoading,
                },
                on: { click: _vm.submitReferenceNo },
              },
              [_vm._v(" Submit Reference No ")]
            )
          : _vm._e(),
        _c(
          "el-button",
          {
            staticStyle: { "min-width": "100px" },
            attrs: { outline: "", size: "small", round: "" },
            on: {
              click: function ($event) {
                _vm.$emit("close", (_vm.noAction = true))
              },
            },
          },
          [_vm._v(" Close ")]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticStyle: { "overflow-y": "auto", height: "calc(100% - 42px)" } },
      [
        _vm.jobData.status && [3, 4, 6].includes(_vm.jobData.status)
          ? _c(
              "base-detail-card",
              { attrs: { title: "Files" } },
              [
                _c("job-files", {
                  attrs: { jobFiles: _vm.jobFiles },
                  on: {
                    "update:jobFiles": function ($event) {
                      _vm.jobFiles = $event
                    },
                    "update:job-files": function ($event) {
                      _vm.jobFiles = $event
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.isEdit
          ? _c(
              "base-detail-card",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: !_vm.jobDataInner,
                    expression: "!jobDataInner",
                  },
                ],
                attrs: { title: "Loading Information" },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "deliveredScheduleForm",
                    attrs: {
                      disabled: !_vm.isJobCreatorOrSellerEditView,
                      model: _vm.jobDataInner,
                      "label-position": "top",
                      "validate-on-rule-change": false,
                    },
                  },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 10 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { sm: 12, md: 8, lg: 4 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Product Name" } },
                              [
                                _c("el-input", {
                                  staticClass: "single-border",
                                  attrs: {
                                    readonly: "",
                                    value: (_vm.jobDataInner || {}).productName,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 12, md: 8, lg: 4 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Barge Name" } },
                              [
                                _c("el-input", {
                                  staticClass: "single-border",
                                  attrs: {
                                    readonly: "",
                                    value: (_vm.jobDataInner || {}).bargeName,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 12, md: 8, lg: 4 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { label: "eBDN Date" },
                              },
                              [
                                _c("el-date-picker", {
                                  staticClass: "single-border",
                                  attrs: { type: "date", readonly: "" },
                                  model: {
                                    value: (_vm.jobDataInner || {}).docDate,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.jobDataInner || {},
                                        "docDate",
                                        $$v
                                      )
                                    },
                                    expression: "(jobDataInner || {}).docDate",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 12, md: 8, lg: 4 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Delivered Quantity" } },
                              [
                                _c("el-input", {
                                  staticClass: "single-border",
                                  attrs: {
                                    readonly: "",
                                    value: (_vm.jobDataInner || {}).docQty,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 12, md: 8, lg: 4 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "ETC" } },
                              [
                                _c("el-date-picker", {
                                  staticClass: "single-border",
                                  attrs: { type: "date", readonly: "" },
                                  model: {
                                    value: (_vm.jobDataInner || {}).etc,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.jobDataInner || {},
                                        "etc",
                                        $$v
                                      )
                                    },
                                    expression: "(jobDataInner || {}).etc",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 12, md: 8, lg: 4 } },
                          [
                            (_vm.jobDataInner || {}).cqPdfId
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: "Documents" } },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: { type: "primary", plain: "" },
                                        on: {
                                          click: function ($event) {
                                            _vm.previewFileMixin(
                                              (_vm.jobDataInner || {}).cqPdfId
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " [" +
                                            _vm._s(
                                              (_vm.jobDataInner || {}).docNumber
                                            ) +
                                            "] CQ File "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 12, md: 8, lg: 4 } },
                          [
                            (_vm.jobDataInner || {}).cqPdfId
                              ? _c(
                                  "el-form-item",
                                  { staticStyle: { "margin-top": "18px" } },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: { type: "primary", plain: "" },
                                        on: {
                                          click: function ($event) {
                                            _vm.previewFileMixin(
                                              (_vm.jobDataInner || {}).coqPdfId
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " [" +
                                            _vm._s(
                                              (_vm.jobDataInner || {}).docNumber
                                            ) +
                                            "] COQ File "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.jobDataInner?.timesheet
                      ? _c("time-sheet-information", {
                          attrs: {
                            jobDataInner: _vm.jobDataInner,
                            jobData: _vm.jobData,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "el-form",
          {
            ref: "schedulForm",
            attrs: {
              model: _vm.schedulFormData,
              disabled:
                !_vm.isJobCreatorOrSellerEditView ||
                _vm.isCanceled ||
                [3, 4, 5, 6].includes(_vm.jobData.status),
              "label-position": "top",
              "validate-on-rule-change": false,
            },
          },
          [
            _c(
              "base-detail-card",
              { attrs: { title: "Product Information" } },
              [
                !_vm.isEdit
                  ? _c(
                      "el-row",
                      { attrs: { gutter: 10 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "outlined",
                                  round: "",
                                  plain: "",
                                  size: "mini",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.dialogTableVisible = true
                                  },
                                },
                              },
                              [_vm._v("Select Order")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-row",
                  { attrs: { gutter: 10 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xs: 20, sm: 20, md: 8 } },
                      [
                        !_vm.isEdit
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "orderId",
                                  label: "Linked Order",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "100%" },
                                  attrs: { disabled: "" },
                                  model: {
                                    value:
                                      _vm.schedulFormData.purchaseOrderNumber,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.schedulFormData,
                                        "purchaseOrderNumber",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "schedulFormData.purchaseOrderNumber",
                                  },
                                }),
                                _c(
                                  "el-dialog",
                                  {
                                    attrs: {
                                      title: "Link Order",
                                      visible: _vm.dialogTableVisible,
                                    },
                                    on: {
                                      "update:visible": function ($event) {
                                        _vm.dialogTableVisible = $event
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "el-table",
                                      {
                                        ref: "multipleTable",
                                        attrs: {
                                          data: _vm.orderTableData,
                                          height: "550px",
                                        },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: { width: "55" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c(
                                                      "el-radio",
                                                      {
                                                        attrs: {
                                                          label: scope.row,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.selectedOrder,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.selectedOrder =
                                                              $$v
                                                          },
                                                          expression:
                                                            "selectedOrder",
                                                        },
                                                      },
                                                      [_c("i")]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2705819809
                                          ),
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "purchaseOrderNo",
                                            label: "Order Number",
                                            width: "170",
                                          },
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "buyerCompanyName",
                                            label: "Buyer ",
                                            width: "200",
                                          },
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "sellerCompanyName",
                                            label: "Seller",
                                            width: "200",
                                          },
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "exWharfLifting.terminal",
                                            label: "Terminal",
                                            width: "150",
                                          },
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "products[0].sysProductName",
                                            label: "Product ",
                                            width: "220",
                                          },
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "products[0].finalQuantity",
                                            label: "Nomination Qty",
                                            width: "150",
                                          },
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "remarks",
                                            label: "Remarks ",
                                            "min-width": "260",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "pagination-container" },
                                      [
                                        _c("el-pagination", {
                                          attrs: {
                                            background: "",
                                            "page-size":
                                              _vm.orderPagination.pageSize,
                                            "page-sizes": [5, 10, 20, 50, 100],
                                            "current-page":
                                              _vm.orderPagination.pageNumber,
                                            layout:
                                              "total, sizes, prev, pager, next",
                                            total: _vm.orderPagination.total,
                                          },
                                          on: {
                                            "size-change":
                                              _vm.handleOrderPageSizeChange,
                                            "current-change":
                                              _vm.handleOrderPageChange,
                                            "update:pageSize": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                _vm.orderPagination,
                                                "pageSize",
                                                $event
                                              )
                                            },
                                            "update:page-size": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                _vm.orderPagination,
                                                "pageSize",
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "dialog-footer",
                                        attrs: { slot: "footer" },
                                        slot: "footer",
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              "min-width": "100px",
                                            },
                                            attrs: {
                                              round: "",
                                              plain: "",
                                              size: "small",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.dialogTableVisible = false
                                              },
                                            },
                                          },
                                          [_vm._v("Close")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              "min-width": "100px",
                                            },
                                            attrs: {
                                              round: "",
                                              type: "primary",
                                              size: "small",
                                            },
                                            on: {
                                              click:
                                                _vm.ConfirmDialogTableVisible,
                                            },
                                          },
                                          [_vm._v("Confirm")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { xs: 4, sm: 4, md: 8 } },
                      [
                        !_vm.isEdit && _vm.withOrder
                          ? _c("el-button", {
                              staticStyle: {
                                position: "absolute",
                                bottom: "15px",
                              },
                              attrs: {
                                type: "danger",
                                icon: "el-icon-delete",
                                circle: "",
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.clearOrder(true)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("el-col", { attrs: { span: 24 } }, [
                      _vm.selectedOrder && _vm.selectedOrder.buyerRemark
                        ? _c("p", [
                            _vm._v(
                              " *Remarks: " +
                                _vm._s(_vm.selectedOrder.buyerRemark) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 10 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { sm: 12, md: 8 } },
                      [
                        _vm.schedulFormData.mitigatingCompanyOrgId ===
                          _vm.currentCompany.id || !_vm.isEdit
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "sellerSysOrganizationId",
                                  label: "Company Name",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      filterable: "",
                                      placeholder: "Select",
                                      clearable: _vm.isEdit,
                                      disabled:
                                        !_vm.isJobCreatorOrSellerEditView ||
                                        _vm.withOrder ||
                                        (!_vm.withOrder && _vm.isEdit),
                                    },
                                    model: {
                                      value:
                                        _vm.schedulFormData
                                          .sellerSysOrganizationId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.schedulFormData,
                                          "sellerSysOrganizationId",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "schedulFormData.sellerSysOrganizationId",
                                    },
                                  },
                                  _vm._l(
                                    _vm.creditAssociationList,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.counterpartyCompanyName,
                                          value:
                                            item.counterpartySysOrganizationId,
                                          disabled:
                                            !_vm.isJobCreatorOrSellerEditView ||
                                            item.status !== "ACTIVE",
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            )
                          : _c(
                              "el-form-item",
                              { attrs: { label: "Company Name" } },
                              [
                                _c("el-input", {
                                  attrs: { disabled: "" },
                                  model: {
                                    value: _vm.schedulFormData.seller,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.schedulFormData,
                                        "seller",
                                        $$v
                                      )
                                    },
                                    expression: "schedulFormData.seller",
                                  },
                                }),
                              ],
                              1
                            ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "stemStartDate",
                              label: "Stem Start Date",
                              rules: _vm.rules.reqRule,
                            },
                          },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                disabled:
                                  !_vm.isJobCreatorOrSellerEditView ||
                                  _vm.withOrder,
                                type: "date",
                                placeholder: "Select",
                              },
                              model: {
                                value: _vm.schedulFormData.stemStartDate,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.schedulFormData,
                                    "stemStartDate",
                                    $$v
                                  )
                                },
                                expression: "schedulFormData.stemStartDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 24, md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "stemEndDate",
                              label: "Stem End Date",
                              rules: _vm.rules.reqRule,
                            },
                          },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                disabled:
                                  !_vm.isJobCreatorOrSellerEditView ||
                                  _vm.withOrder,
                                type: "date",
                                placeholder: "Select",
                              },
                              model: {
                                value: _vm.schedulFormData.stemEndDate,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.schedulFormData,
                                    "stemEndDate",
                                    $$v
                                  )
                                },
                                expression: "schedulFormData.stemEndDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 10 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { sm: 12, md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "sysProductId",
                              label: "Product Name",
                              rules: _vm.rules.reqRule,
                            },
                          },
                          [
                            _vm.withOrder
                              ? _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "Select",
                                      disabled:
                                        !_vm.isJobCreatorOrSellerEditView ||
                                        (_vm.isEdit && _vm.withOrder),
                                    },
                                    on: { change: _vm.changeProduct },
                                    model: {
                                      value: _vm.schedulFormData.sysProductId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.schedulFormData,
                                          "sysProductId",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "schedulFormData.sysProductId",
                                    },
                                  },
                                  _vm._l(
                                    _vm.selectedOrder.products,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.sysProductId,
                                        attrs: {
                                          label: item.sysProductName,
                                          value: item.sysProductId,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                )
                              : _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "Select",
                                      loading:
                                        !_vm.sysProductOptions.length > 0,
                                    },
                                    on: {
                                      change: _vm.changeProduct,
                                      "visible-change":
                                        _vm.getProductListSortByRecent,
                                    },
                                    model: {
                                      value: _vm.schedulFormData.sysProductId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.schedulFormData,
                                          "sysProductId",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "schedulFormData.sysProductId",
                                    },
                                  },
                                  _vm._l(
                                    _vm.sysProductOptions,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "sysProductSpecificationId",
                              label: "Spec",
                              rules: _vm.rules.reqRule,
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: "Select",
                                  disabled:
                                    !_vm.isJobCreatorOrSellerEditView ||
                                    _vm.withOrder,
                                },
                                on: { change: _vm.changeProductSpecification },
                                model: {
                                  value:
                                    _vm.schedulFormData
                                      .sysProductSpecificationId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.schedulFormData,
                                      "sysProductSpecificationId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "schedulFormData.sysProductSpecificationId",
                                },
                              },
                              _vm._l(
                                _vm.sysProductSpecificationOptions,
                                function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 24, md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "loadingQty",
                              label: "Loading Quantity",
                              rules: _vm.rules.loadingQty,
                            },
                          },
                          [
                            _c(
                              "el-input",
                              {
                                staticClass: "input-with-select",
                                attrs: { type: "number" },
                                model: {
                                  value: _vm.schedulFormData.loadingQty,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.schedulFormData,
                                      "loadingQty",
                                      $$v
                                    )
                                  },
                                  expression: "schedulFormData.loadingQty",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { slot: "append", disabled: "" },
                                    slot: "append",
                                    model: {
                                      value: _vm.schedulFormData.qtyUnitMetric,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.schedulFormData,
                                          "qtyUnitMetric",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "schedulFormData.qtyUnitMetric",
                                    },
                                  },
                                  [
                                    _c("el-option", { attrs: { value: "MT" } }),
                                    _c("el-option", {
                                      attrs: { value: "CBM" },
                                    }),
                                    _c("el-option", {
                                      attrs: { value: "BARRELS" },
                                    }),
                                    _c("el-option", { attrs: { value: "%" } }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.withOrder
                          ? _c("div", { staticClass: "BalanceDiv" }, [
                              _c("div", [
                                _c("div", [_vm._v("Quantity Balance:")]),
                                _c("div", [
                                  _vm._v(_vm._s(_vm.qtyBalance) + "MT"),
                                ]),
                              ]),
                              _c("div", [
                                _c("div", [_vm._v("Quantity Assigned:")]),
                                _c("div", [
                                  _vm._v(_vm._s(_vm.qtyAssigned) + "MT"),
                                ]),
                              ]),
                              _c("div", [
                                _c("div", [_vm._v("Delivered:")]),
                                _c("div", [
                                  _vm._v(
                                    _vm._s(_vm.balanceData.qtyDelivered) + "MT"
                                  ),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 10 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { sm: 24, md: 8 } },
                      [
                        _vm.jobData.status == 4 &&
                        _vm.isJobCreatorOrSellerEditView
                          ? _c(
                              "el-form",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "referenceNo",
                                      label: "Reference No.",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: _vm.schedulFormData.referenceNo,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.schedulFormData,
                                            "referenceNo",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "schedulFormData.referenceNo",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "referenceNo",
                                  label: "Reference No.",
                                },
                              },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.schedulFormData.referenceNo,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.schedulFormData,
                                        "referenceNo",
                                        $$v
                                      )
                                    },
                                    expression: "schedulFormData.referenceNo",
                                  },
                                }),
                              ],
                              1
                            ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("fuel-type-related-information", {
                  attrs: {
                    schedulFormData: _vm.schedulFormData,
                    selectedProduct: _vm.selectedProduct,
                  },
                  on: {
                    "update:schedulFormData": function ($event) {
                      _vm.schedulFormData = $event
                    },
                    "update:schedul-form-data": function ($event) {
                      _vm.schedulFormData = $event
                    },
                    "update:selectedProduct": function ($event) {
                      _vm.selectedProduct = $event
                    },
                    "update:selected-product": function ($event) {
                      _vm.selectedProduct = $event
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "base-detail-card",
              { attrs: { title: "Barge Information" } },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 10 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { sm: 12, md: 8 } },
                      [
                        _vm.schedulFormData.mitigatingCompanyOrgId ===
                          _vm.currentCompany.id || !_vm.isEdit
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "bargeName",
                                  label: "Barge Name",
                                },
                              },
                              [
                                _c("barge-or-vessel-selector", {
                                  attrs: {
                                    disabled:
                                      !_vm.isJobCreatorOrSellerEditView ||
                                      (_vm.jobData.status !== 1 && _vm.isEdit),
                                    schedulFormData: _vm.schedulFormData,
                                    availableBargesLoading: false,
                                    availableBargesOptions:
                                      _vm.currentCompanyShips.filter(
                                        (item) =>
                                          !(
                                            (item.imo &&
                                              item.imo ===
                                                _vm.schedulFormData
                                                  .vesselImo) ||
                                            item.id ===
                                              _vm.schedulFormData.vesselShipId
                                          )
                                      ),
                                  },
                                  on: {
                                    handleChangeBarge: (val) =>
                                      _vm.changeVesselName(val, true),
                                    visibleChange: () => {},
                                  },
                                }),
                                _vm.bargeMpaStatus
                                  ? _c(
                                      "span",
                                      { staticStyle: { "font-size": "10px" } },
                                      [
                                        _vm._v(
                                          "(" + _vm._s(_vm.bargeMpaStatus) + ")"
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _c(
                              "el-form-item",
                              { attrs: { label: "Barge Name" } },
                              [
                                _c("el-input", {
                                  attrs: { disabled: "" },
                                  model: {
                                    value: _vm.schedulFormData.bargeName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.schedulFormData,
                                        "bargeName",
                                        $$v
                                      )
                                    },
                                    expression: "schedulFormData.bargeName",
                                  },
                                }),
                                _vm.bargeMpaStatus
                                  ? _c(
                                      "span",
                                      { staticStyle: { "font-size": "10px" } },
                                      [
                                        _vm._v(
                                          "(" + _vm._s(_vm.bargeMpaStatus) + ")"
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "SB No." } },
                          [
                            _c("el-input", {
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.schedulFormData.bargeLicenseNo,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.schedulFormData,
                                    "bargeLicenseNo",
                                    $$v
                                  )
                                },
                                expression: "schedulFormData.bargeLicenseNo",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "base-detail-card",
              { attrs: { title: "Loading Information" } },
              [
                _c("div", { staticClass: "product-container product" }, [
                  _c("div", { staticClass: "single-row-container" }, [
                    _c(
                      "div",
                      { staticClass: "label-margin" },
                      [
                        _c("span", { staticClass: "span-margin" }, [
                          _vm._v(" Location: "),
                        ]),
                        _c(
                          "el-radio-group",
                          {
                            on: { change: _vm.changeExwharfType },
                            model: {
                              value: _vm.schedulFormData.exWharfType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.schedulFormData,
                                  "exWharfType",
                                  $$v
                                )
                              },
                              expression: "schedulFormData.exWharfType",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("Terminal"),
                            ]),
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("Floater"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c(
                  "el-row",
                  { attrs: { gutter: 10 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { sm: 12, md: 8, lg: 6 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "port",
                              label: "Port",
                              rules: _vm.rules.reqRule,
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.schedulFormData.port,
                                callback: function ($$v) {
                                  _vm.$set(_vm.schedulFormData, "port", $$v)
                                },
                                expression: "schedulFormData.port",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, md: 8, lg: 6 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "loadingTime",
                              label: "Date & Time",
                            },
                          },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                type: "datetime",
                                format: "yyyy-MM-dd HH:mm",
                                placeholder: "Select",
                              },
                              model: {
                                value: _vm.schedulFormData.loadingTime,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.schedulFormData,
                                    "loadingTime",
                                    $$v
                                  )
                                },
                                expression: "schedulFormData.loadingTime",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, md: 8, lg: 6 } },
                      [
                        _vm.schedulFormData.exWharfType === 1
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "floaterName",
                                  label: "Floater Name",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "100%" },
                                  attrs: { disabled: _vm.prodNameDisabled },
                                  model: {
                                    value: _vm.schedulFormData.floaterName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.schedulFormData,
                                        "floaterName",
                                        $$v
                                      )
                                    },
                                    expression: "schedulFormData.floaterName",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, md: 8, lg: 6 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "locationLevel",
                              label: "Anchorage/Berth",
                            },
                          },
                          [
                            _c("el-cascader", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                clearable: "",
                                "popper-class": "el-cascader-long",
                                options: _vm.locationList,
                                props: _vm.locationOptionProps,
                              },
                              model: {
                                value: _vm.schedulFormData.locationLevel,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.schedulFormData,
                                    "locationLevel",
                                    $$v
                                  )
                                },
                                expression: "schedulFormData.locationLevel",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, md: 8, lg: 6 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Operation Date Base On",
                              rules: _vm.rules.reqRule,
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: "Please Select",
                                  disabled:
                                    _vm.isDisabledAllowOperationDateChange,
                                },
                                model: {
                                  value:
                                    _vm.schedulFormData
                                      .selectedOperationDateType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.schedulFormData,
                                      "selectedOperationDateType",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "schedulFormData.selectedOperationDateType",
                                },
                              },
                              _vm._l(
                                _vm.LOADING_OPERATION_DATE_OPTIONS,
                                function (item) {
                                  return _c("el-option", {
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 10 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "remark", label: "Remarks" } },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea", rows: 2 },
                              model: {
                                value: _vm.schedulFormData.remark,
                                callback: function ($$v) {
                                  _vm.$set(_vm.schedulFormData, "remark", $$v)
                                },
                                expression: "schedulFormData.remark",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form",
              [
                _vm.jobData.status === 4
                  ? _c(
                      "base-detail-card",
                      {
                        attrs: {
                          title: "MPA Transaction Information",
                          action: _vm.mpaInfoActions,
                        },
                      },
                      [
                        _c("mpa-transaction-information", {
                          attrs: { mpaTransactionData: _vm.mpaTransactionData },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }